<div class="my-login-page">
<section class="h-100">
  <div class="container h-100">
    <div class="row justify-content-md-center h-100">
      <div class="card-wrapper">
        <div class="brand">
          <img src="assets/images/logo-lg-afh.png" alt="logo">
        </div>
        <div class="card fat">
          <div class="card-body">
            <h4 class="card-title">{{ typealert }} -{{ alertstatus }} Bienvenue sur AFHunt <span style="text-decoration: underline;">Noel Kenfack</span></h4>
            <div>Nous avons envoyé votre lien de connexion par email/sms, ouvrez votre boite et suivez le ce lien pour activer votre compte.</div>
            <form method="POST" class="my-login-validation" (ngSubmit)="onSubmit(f)" #f="ngForm">
              <div class="form-group">
                <label for="code" style="margin-top: 15px;">
                  <strong>Vous pouvez rentrer votre code d'activation ici</strong>
                </label>
                <input id="code" type="text" class="form-control" name="code" value="" ngModel required autofocus placeholder="Code"/>
                <div class="invalid-feedback">
                  Email is invalid
                </div>
              </div>

              <div class="form-group m-0">
                <button type="submit" class="btn btn-primary btn-block" [disabled]="f.invalid">
                  Activer mon compte
                </button>
              </div>
              <div class="mt-4 text-center"> 
                Vous n'avez pas reçu votre code ?

                <!-- COPY -->
                <div>
                  <div bgcolor="#111111" align="center" style="padding: 0px 30px 20px 30px; color: #666666; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px;" >
                    <p style="margin: 0;">Cliquez sur le lien ci-dessus pour envoyer à nouveau</p>
                    <div class="">
                      <a href="" class="btn btn-primary" >Valider mon compte</a>
                    </div>
                  </div>
                </div>


              </div>
            </form>
          </div>
        </div>
        <div class="footer">
          Copyright &copy; 2017 &mdash; Your Company 
        </div>
      </div>
    </div>
  </div>
</section>

<div style="width: 100%; position: fixed;bottom: 0; background-color: #fff;box-shadow: 0 4px 8px rgba(0,0,0,.05);border-radius: 3px 3px 0 0;font-size: 12px;padding: 5px 10px;">

  <div class="container">
    Copyright &copy; 2021 &mdash; AFHunt
    <span class="float-right"><a href="#!">Conditions d'utilisations</a> &nbsp;&bull;&nbsp; <a href="#!">Confidentialité</a></span>
  </div>

</div>
</div>
